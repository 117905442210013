
.project-card {
    text-align: center;
    padding: 25px;
    border: 1px solid #59e3a7;
    background-color: #fbfbfb;
    border-radius: 15px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  .project-card:hover, .project-card.active {
    border-color: #263238;
    -webkit-box-shadow: 0px 9px 35px 0px rgba(0, 0, 0, 0.09);
            box-shadow: 0px 9px 35px 0px rgba(0, 0, 0, 0.09);
  }
  
  .project-card .thumb img{
    border-radius: 10%;
  }
  
  .project-card .content ul{
    text-align: left;
  }
  
  .project-card .content h6{
    margin-top: 1%;
  }

  .project-card .content ul .tag{
    display: inline-block;
    background-color: #f0f0f0; 
    color: #333; 
    padding: 5px 10px; 
    margin: 2px; 
    border-radius: 5px;
    font-size: .7em;
    font-weight: 500;
  }

  .project-card .content ul .feature{
    list-style-type: circle;
    font-size: .9em;
  }

  .project-card .content .title {
    color: #181c51;
    line-height: 1.666;
    font-weight: 600;
    margin-bottom: 0;
    font-size: 18px;
  }
